
import React from "react"
import ImgInvitatie from "./ImgInvitatie";

import "../css/ImgInvitatie.css"
import background from "../images/fi.png"

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >

            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4" >
                <div className="card p-0 overflow-hidden h-100 shadow" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>


                    <div class="card-body">
                        
                            <h1 style={{ color: 'grey' }}>Invitație</h1>
                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />

                            </h5>
                            <h1 style={{ fontSize: window.innerWidth >= 500 ? '30px' : '20px', color: 'green', padding: '20px'}}>
                            Am răsărit dintr-o minune <br/>
Sau dintr-un vis, aș putea spune. <br/>
<b style={{color: 'grey'}}>Emil</b> mă numesc <br/>
și pe surioara - <b style={{color: 'grey'}}> Elisa </b>o iubesc. <br/>
Împreună cu părinții  <br/>
<b style={{color: 'grey'}}>Viorel și Nadejda</b> <br/>
Un anunț important avem de făcut: <br/>
<b style={{color: 'grey'}}>1 Anișor</b> împlinesc <br/>
 Și tot odată <b style={{color: 'grey'}} >Cumetria</b> <br/>
Vreu să sărbătoresc! <br/>
Cu mult drag și încântare <br/>
Vă invităm la o petrecere tare! <br/>
Cu muzică și voie bună <br/>
Ne vom distra clar împreună! <br/>
Vă așteptăm cu mare drag, <br/>
Joi, <b style={{color: 'grey'}}>1 august,</b> ora <b style={{color: 'grey'}}>17:30</b><br/>
în incinta Restaurantului <br/> <b style={{color: 'grey'}}>”Avegarden”</b>, <br/>
str. Socoleni 5/3, Chișinău. <br/>
Haide, nu te mai gândi, <br/>
Petrecere ca asta nu va mai fi!
<br/>
                            </h1>








                      

                       
                        <div id="gol"></div>








                        <h4 class="card-footer" style={{ color: 'green' }}>
                        
                            </h4>

                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;
import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";
import CardLocal from "../cardLocal";
import FormSection from "../FormSection";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import CardInvitatie from "./CardInvitatie";
import Gallery from "./Gallery";
import music from '../images/music.png';
import muz from '../video/kids.mp3'


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 500 ? '750px': '350px'} opacity=".8" >
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mt-5" style={{ maxWidth: '600px', paddingTop: '300px'  }}>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '55px', color: 'green' }}>{item.copilul}</h1>
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ fontSize: '36px', fontFamily: 'Arial', color: '#70AD47',  }}>{item.savedata}<br />{item.data}  </h4>
                                <h5 className="" style={{fontSize: '30px', fontFamily: 'Comic Sans MS', color: 'green' }}>{item.savedata1}</h5>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



<div className="text-center"style={{ maxWidth: '650px', padding: '15px', margin: 'auto' }} >
                <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} style={{height: '100px'}} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>              
            </div>


            <BlogSectionNoi />
        
        {/** <Gallery/>*/}



            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500? item.imgdesktop : item.imgmobil} height="750px" opacity=".8">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-3" style={{ maxWidth: '600px', padding: '20px', color: 'green',fontFamily: 'Comic Sans MS'  }}>
                            <h2 className="text-center pt-4">
                {item.title1} <br/>
                {item.title2}<br/>
                {item.title3}<br/>
                {item.message}<br/>
              </h2>
           
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 
{/** 
<BlogSectionNasii/>*/}

            <CardInvitatie/>

           <div className="container text-center mb-5 pb-4" style={{maxWidth: '600px', padding: '20px' }}>
    <h2 style={{fontFamily: 'Comic Sans MS', color: 'green'}}>Au rămas clipe numărate până ne vedem!</h2>
 <Timer />
</div>

         <CardLocal />
    

            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                        {item.familia}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;
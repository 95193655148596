import restaurant from "./images/avegarden.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/1r.jpg";
import img_card_nasi from "./images/COPII.jpg";
import imgheadermb from "./images/f8.png";
import imgheader from "./images/f7.png";
import imgheadermiini from "./images/fo1.jpeg";
import imgheadermiinimb from "./images/fo1.jpeg";
import logo from './images/logo.png'



const data = {
    introData: [{
        copilul: "",
        familia: "Emil",
        logo: logo,
        tata: "",
        mama: "Nadejda",
        data: "",
        data_confirmare: "15 iulie 2024",
        savedata: "",
        savedata1: "",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "butnaru.nadejda@gmail.com", 
       tel: "+37368331424",
       phone: "tel:+37368331424",
       viber: "viber://chat?number=%2B37368331424",
       whatsapp: "https://wa.me/+37368331424",
       messenger: "https://www.messenger.com/t/nadejda.butnaru",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Avegarden",
            data: "1 august 2024, joi, ora 17:30",
            adress: "Strada Socoleni 5/3, Chișinău",
            harta: "https://maps.app.goo.gl/DwTXPn6QPqiqUzqh7",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10871.593382650679!2d28.8503363!3d47.0618444!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97ddfe95690c5%3A0xac60947a8d909966!2sAvegarden!5e0!3m2!1sro!2s!4v1716901473255!5m2!1sro!2s"   
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Salutare !!!",
          
            message: "Pentru că ne este dor de voi,  ",
            message1:'Pentru că s-au adunat mai multe motive, ',
            message2:"Demne de o MEGA Sărbătoare,",
            message3:'Am zis că....Hai s-o facem și pe asta: ',
            message4:"Aniversarea - 1 Anișor și Cumetria",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_card_nasi,
            title1: "Invitație",
            title2: "Cumetria Dxxx",
            title3: "Aniversarea și Cumetria Sxxx",
            message: "Pentru că ne este dor de voi 🙂    ",
            message1:'Pentru că s-au adunat mai multe motive demne de o MEGA Sărbătoare,',
            message2:"Am zis că... HAI s'o facem și pe asta - Perecerea URȘILOR!🙂 ",

        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title1: 'Vă așteptăm, cu mic și mare',
            title2: "Cu mult drag și încântare!",
            title3: "Cu muzică și voie bună,",
            message: " Ne vom distra, de minune!",  
        }
    ],

}

export default data;